import type { EnrichedContractProduct } from '@/shared/services/campaignDataResolver'

import type { HousingOptions } from '../components/QuickSearch/types'

const MAX_YEARLY_CONSUMPTION_FOR_APARTMENT = 8000

/**
 * In Sweden we need to select different contract base on the estimatedConsumption for B2C.
 *
 * >=MAX_YEARLY_CONSUMPTION_FOR_APARTMENT then villa
 * <MAX_YEARLY_CONSUMPTION_FOR_APARTMENT then apartment
 */
const getContractHousingTypeByConsumption = (
  estimatedConsumption?: number,
): Extract<HousingOptions, 'apartment' | 'villa'> => {
  if (
    estimatedConsumption !== undefined &&
    estimatedConsumption >= MAX_YEARLY_CONSUMPTION_FOR_APARTMENT
  ) {
    return 'villa'
  }

  return 'apartment'
}

/**
 * Method that pick right contract template from contract product base on campaignId and estimated consumption
 * - no contract templates in contract product -> throw error
 * - only one contract with same campaign id -> return
 * - more than one contract with same campaign id -> return base on the estimatedConsumption
 * - otherwise throw error (something unexpected has happened)
 */
export const getActiveContractTemplate = (
  contractProduct: EnrichedContractProduct,
  campaignId?: number | null,
  estimatedConsumption?: number,
) => {
  const campaignsContractTemplates = contractProduct.contractTemplates?.filter(
    (ct) => ct.campaignId === campaignId,
  )
  const errorMsg = `Can not find contractTemplate in ContractProduct - title: ${contractProduct?.cfData.title} campaignId: ${campaignId} estimatedConsumption: ${estimatedConsumption}`

  if (campaignsContractTemplates?.length === 0) {
    throw new Error(errorMsg)
  }

  if (campaignsContractTemplates?.length === 1) {
    return campaignsContractTemplates[0]
  }

  const housingType = getContractHousingTypeByConsumption(estimatedConsumption)

  if (housingType) {
    const contractTemplate = campaignsContractTemplates?.find((ct) =>
      ct.cfData?.houseType?.includes(housingType),
    )
    if (contractTemplate) {
      return contractTemplate
    }
    throw new Error(errorMsg)
  }

  throw new Error(errorMsg)
}
