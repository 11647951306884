/**
 * Checks if the given timestamp has expired based on the provided duration.
 *
 * @param startTime - The starting time in milliseconds.
 * @param expireDuration - The duration in milliseconds after which the time is considered expired.
 * @returns `true` if the time has expired, otherwise `false`.
 */
export const hasExpired = (startTime: number, expireDuration: number): boolean => {
  return Date.now() > startTime + expireDuration
}
