'use client'

import type { PersistStorage, StorageValue } from 'zustand/middleware'

import type { Store } from './types'
import { browserEnvs } from '../envs'
import { getDefaultLocale } from '../locale'
import { logError } from '../utils/error'
import { hasExpired } from '../utils/time/expiration'

type StorageTypeOption = 'local' | 'session'
type StorageSlice = keyof Store
export type StorageConfiguration = {
  [K in StorageSlice]?: {
    type: StorageTypeOption
    expiration?: number
  }
}
type ExpirableData<T> = {
  timestamp: number
  data: T
}

/**
 * The method is used to clean up from data persistance
 * flow all properties that starts with an underscore
 * in the first level of slice depth.
 */
const cleanupHidden = (source: Record<string, unknown>) => {
  const result: Record<string, unknown> = {}

  for (const key in source) {
    if (key.charAt(0) !== '_') {
      result[key] = source[key]
    }
  }

  return result
}

/**
 * Simple type guard to ensure that we need to parse
 * and cleanse data from slice. If slice is a
 * primitive - we don't need to run cleanup on it.
 */
const isParsableObject = (source: unknown): source is Record<string, unknown> => {
  if (typeof source === 'object' && !Array.isArray(source) && source !== null) {
    return true
  }

  return false
}

/**
 * Simple prefixing method to ensure that we have
 * a unique key for each country in the storage.
 */
const getPrefixedKey = (key: string): string => {
  const locale = getDefaultLocale(browserEnvs.NEXT_PUBLIC_COUNTRY)
  return `${browserEnvs.NEXT_PUBLIC_COUNTRY}-${locale}:${key}`
}

/**
 * @description
 * Methot to create storage object that can be passed
 * as a parameter into a zustand's persist middleware.
 *
 * Main feature - allows to specify in config how and
 * where data should be persisted.
 *
 * - Each item present in the config will be persisted in
 * the storage, depending on the property value.
 * - If store property is skipped in config - it will not
 * be persisted.
 * - If property's childe is starting with underscore - it
 * will be skipped during recording/hydration.
 *
 * @returns
 * Object with methods that parse provided configuration
 * and depending on storage type in configuration,
 * fetches/sets/removes persisted data
 * from the corresponding store type per slice.
 *
 * The name for storage is always equal to the key value in
 * store object, provided persistance name is ignored.
 */
export const createStorage = (
  persistConfiguration: StorageConfiguration,
): PersistStorage<Partial<Store>> => {
  const definePartialStorage = (name: StorageSlice): Storage => {
    switch (persistConfiguration[name]?.type) {
      case 'local':
        return localStorage
      case 'session':
        return sessionStorage
      default:
        throw new TypeError(`No storage type for ${name} available in the config.`)
    }
  }
  return {
    getItem: () => {
      // Collect the list of raw slices from config
      const keys = Object.keys(persistConfiguration) as Array<keyof typeof persistConfiguration>

      // Obtain data per key in persisted storages
      const persistedArray: [StorageSlice, string | null][] = keys.map((key) => {
        const storageInstance = definePartialStorage(key)
        const prefixedKey = getPrefixedKey(key)

        if (!storageInstance) {
          throw new TypeError(`Storage type for key ${key} is not defined properly.`)
        }

        const storedData = storageInstance.getItem(prefixedKey)
        if (!storedData) {
          return [key, null]
        }

        const config = persistConfiguration[key]
        const parsedData = JSON.parse(storedData)

        if (config?.expiration) {
          // Check if parsed data has the expected structure for expirable data
          if (
            parsedData &&
            typeof parsedData === 'object' &&
            'timestamp' in parsedData &&
            'data' in parsedData
          ) {
            const expirableData = parsedData as ExpirableData<unknown>
            if (hasExpired(expirableData.timestamp, config.expiration)) {
              storageInstance.removeItem(prefixedKey)
              return [key, null]
            }
            return [key, JSON.stringify(expirableData.data)]
          }

          // Fallback if the data doesn't match the expected structure
          return [key, null]
        }

        return [key, storedData]
      })

      // Parse persisted data, combine it into store and return.
      try {
        const parsedStoreComposition: Record<string, unknown> = {}

        for (const slice of persistedArray) {
          const [key, data] = slice

          /**
           * Removing this null return statement results in empty
           * string data overriding default store state.
           */
          if (!data) {
            return null
          }

          const parsedData = JSON.parse(data)
          parsedStoreComposition[key] = isParsableObject(parsedData)
            ? cleanupHidden(parsedData)
            : parsedData
        }

        return {
          state: parsedStoreComposition,
        }
      } catch (error) {
        logError(error)

        throw new TypeError(`Error during parsing store data :(`)
      }
    },

    setItem: (_name, value) => {
      // Collect the list of raw slices from config
      const keys = Object.keys(persistConfiguration) as Array<keyof typeof persistConfiguration>

      // For each key, set value into the storage
      for (const key of keys) {
        const storageInstance = definePartialStorage(key)
        const prefixedKey = getPrefixedKey(key)

        if (!storageInstance) {
          throw new TypeError(`Storage type for key ${key} is not defined properly.`)
        }

        const { state } = value as StorageValue<Pick<Store, keyof typeof persistConfiguration>>

        const cleansedData = isParsableObject(state[key]) ? cleanupHidden(state[key]) : state[key]
        let dataToStore = JSON.stringify(cleansedData)

        const config = persistConfiguration[key]
        if (config?.expiration) {
          const timestamp = Date.now()
          const dataWithTimestamp = {
            timestamp,
            data: cleansedData,
          }
          dataToStore = JSON.stringify(dataWithTimestamp)
        }

        storageInstance.setItem(prefixedKey, dataToStore)
      }
    },

    removeItem: () => {
      // Collect the list of raw slices
      const keys = Object.keys(persistConfiguration) as Array<keyof typeof persistConfiguration>

      for (const key of keys) {
        const storageInstance = definePartialStorage(key)
        const prefixedKey = getPrefixedKey(key)

        if (!storageInstance) {
          throw new TypeError(`Storage type for key ${key} is not defined properly.`)
        }

        storageInstance.removeItem(prefixedKey)
      }
    },
  }
}
