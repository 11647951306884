import type { StateCreator, StoreMutatorIdentifier } from 'zustand'

import type { Store } from './types'

type Middleware = <
  T,
  Mps extends [StoreMutatorIdentifier, unknown][] = [],
  Mcs extends [StoreMutatorIdentifier, unknown][] = [],
  R = T,
>(
  creator: StateCreator<T, Mps, Mcs, R>,
  callback: (previousState: Store, ...a: Parameters<typeof creator>) => void,
) => StateCreator<T, Mps, Mcs, R>

type MiddlewareImpl = (
  creator: StateCreator<Store, [], []>,
  callback: (previousState: Store, ...a: Parameters<typeof creator>) => void,
) => StateCreator<Store, [], []>

const middlewareImpl: MiddlewareImpl = (creator, callback) => (set, get, store) => {
  const middlewareSet: typeof set = (...a) => {
    const previousState = store.getState()

    set(...a)

    callback(previousState, middlewareSet, get, store)
  }

  const setState = store.setState
  store.setState = (...a) => {
    setState(...a)
  }

  return creator(middlewareSet, get, store)
}

/**
 * This is a generic middleware implementation. Details
 * can be received in the documentation:
 *
 * https://docs.pmnd.rs/zustand/guides/typescript#middleware-that-doesn't-change-the-store-type
 *
 * This middleware interferes with default SET function
 * and allows you to call a separate callback on every
 * SET invocation.
 *
 * !!! IMPORTANT !!!
 *
 * While using middleware, please, pay attention
 * to callback function executions, since by not specifying
 * invoce conditions it's easy to end up in infinite
 * dispatch->middleware->dispatch ... loop.
 */
export const middleware = middlewareImpl as unknown as Middleware
