import { produce } from 'immer'
import type { StateCreator } from 'zustand'

import type { Store } from '../types'

type SystemState = {
  /**
   * An information about that store is rehydrated with persisted data
   * NOTE: This is documented way of getting that information https://docs.pmnd.rs/zustand/integrations/persisting-store-data#how-can-i-check-if-my-store-has-been-hydrated
   * However there is another way of making it work with hasHydrated but it didn't work for me with out persisted store
   */
  _isHydrated?: boolean
}

type SystemActions = {
  _setIsHydrated: (hydrated?: boolean) => void
}

export type SystemStore = {
  system: SystemState & SystemActions
}

// Good practice to keep it still, even while empty
export const initialState: SystemState = {}

export const createSystemSlice: StateCreator<
  Store,
  [['zustand/persist', unknown]],
  [],
  SystemStore
> = (set, get) => ({
  system: {
    ...initialState,
    _isHydrated: false,
    _setIsHydrated: (hydrated) =>
      set(
        produce(get(), (state: Store) => {
          state.system._isHydrated = hydrated
        }),
      ),
  },
})
