import deepmerge from 'deepmerge'
import type { StateCreator } from 'zustand'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { createCheckoutDataSlice } from './slices/checkoutDataSlice'
import { createCheckoutFlowSlice } from './slices/checkoutFlowSlice'
import { createCustomerSlice } from './slices/customerSlice'
import { createEnterpriseCustomerSlice } from './slices/enterpriseCustomerSlice'
import { createErrorSlice } from './slices/errorSlice'
import { createGtmSlice } from './slices/gtmSlice'
import { createHousingInfoSlice } from './slices/housingSlice'
import { createPartnerFieldsSlice } from './slices/partnerFieldsSlice'
import { createSelectedContractSlice } from './slices/selectedContractSlice'
import { createSystemSlice } from './slices/systemSlice'
import { type StorageConfiguration, createStorage } from './storage'
import type { Store } from './types'
import { logError } from '../utils/error'
import { createQuizFlowSlice } from './slices/quizFlowSlice'

/**
 * Storage configuration
 *
 * Defines the way how and where data should be persisted,
 * depending on the separate slices. If you want data to
 * be persisted - enter your slice name and set expected
 * storage method.
 *
 * Ommited slices will not be persisted in any type of storage.
 */
const storageConfiguration: StorageConfiguration = {
  partnerFields: { type: 'session' },
  housingInfo: { type: 'local' },
  selectedContract: { type: 'session', expiration: 1000 * 60 * 60 * 24 },
  customer: { type: 'session' },
  enterpriseCustomer: { type: 'session' },
}

/**
 * @description
 * Creates a StateCreator for global store object,
 * used as a parameter in a zustand's `create` function.
 */
export const globalStoreCreator: StateCreator<Store, [['zustand/persist', unknown]], [], Store> = (
  ...props
) => ({
  ...createPartnerFieldsSlice(...props),
  ...createCheckoutFlowSlice(...props),
  ...createCheckoutDataSlice(...props),
  ...createCustomerSlice(...props),
  ...createEnterpriseCustomerSlice(...props),
  ...createSystemSlice(...props),
  ...createHousingInfoSlice(...props),
  ...createSelectedContractSlice(...props),
  ...createErrorSlice(...props),
  ...createGtmSlice(...props),
  ...createQuizFlowSlice(...props),
})

/**
 * @description
 * Creates the store object, that should be used
 * in a context provider to be properly accessible.
 */
export const createStore = (shouldPersist?: boolean) =>
  create<Store, [['zustand/persist', unknown]]>(
    persist(globalStoreCreator, {
      /**
       * Note: this `name` is ignored in storage logic
       * and only needed due to zustand typing.
       */
      name: 'storage',
      storage:
        typeof window !== 'undefined'
          ? createStorage(shouldPersist ? storageConfiguration : {})
          : undefined,
      /**
       * Merge needed to maintain nested actions.
       * In case if it causes any performance issues - should be revisited.
       */
      merge: (persistedState = {}, currentState) =>
        deepmerge<Store>(currentState, persistedState as Partial<Store>, {
          arrayMerge: (_, sourceArray) => sourceArray,
        }),
      onRehydrateStorage: () => {
        return (state, error) => {
          /**
           * In storybook we don't use persisted store, and if needed want to set this prop manually
           */
          if (process.env.STORYBOOK) {
            return
          }

          state?.system._setIsHydrated(true)
          if (error) {
            logError(error)
          }
        }
      },
    }),
  )
