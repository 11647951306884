import type {
  Address,
  ContactInformation,
  Enterprise,
  EnterpriseContactInformation,
  Person,
} from './types'

export const ENTERPRISE_DEFAULTS: Enterprise = {
  _name: '',
  _orgId: '',
}

export const PERSON_DEFAULTS: Person = {
  _firstName: '',
  _lastName: '',
  _birthdate: '',
  _ssn: '',
}

export const ADDRESS_DEFAULTS: Address = {
  streetName: '',
  city: '',
  postalCode: '',
  houseNumber: '',
  residence: '',
}

export const CONTACT_INFORMATION_DEFAULTS: ContactInformation = {
  email: '',
  phoneNumber: '',
}

export const ENTERPRISE_CONTACT_INFORMATION_DEFAULTS: EnterpriseContactInformation = {
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
}
