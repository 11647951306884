import { produce } from 'immer'
import type { StateCreator } from 'zustand'

import { ADDRESS_DEFAULTS, CONTACT_INFORMATION_DEFAULTS, PERSON_DEFAULTS } from '../defaults'
import { middleware } from '../middleware'
import type { Address, ContactInformation, Person, Store } from '../types'

export type CustomerState = Person & {
  _contactInformation: ContactInformation
  _address: Address
  _isExistingCustomer?: boolean
}

export type CustomerActions = {
  setCustomer: (person: Person, address: Address, isExistingCustomer: boolean) => void
  setContactInformation: (contact: ContactInformation) => void
}

export type CustomerStore = {
  customer: CustomerState & CustomerActions
}

export const initialState: CustomerState = {
  _address: ADDRESS_DEFAULTS,
  _contactInformation: CONTACT_INFORMATION_DEFAULTS,
  _isExistingCustomer: undefined,
  ...PERSON_DEFAULTS,
}

export const creator: StateCreator<Store, [['zustand/persist', unknown]], [], CustomerStore> = (
  set,
  get,
) => ({
  customer: {
    ...initialState,
    setCustomer: (person, address, isExistingCustomer) =>
      set(
        produce(get(), (state: Store) => {
          state.customer = { ...state.customer, ...person }
          state.customer._address = address
          state.customer._isExistingCustomer = isExistingCustomer
        }),
      ),
    setContactInformation: (contact) =>
      set(
        produce(get(), (state: Store) => {
          state.customer._contactInformation = contact
        }),
      ),
  },
})

export const createCustomerSlice = middleware(creator, (_prevState, _set, _get, _store) => {})
